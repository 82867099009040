<template>
  <div class="service">
    <div class="content1" />
    <div class="title" style="margin-top: 60px"><span class="blue">全流程</span>深度服务</div>
    <div class="service-tips">
      三师共管，定向评估，分期定制个人专属学习备考方案，针对施教；<br>
      每日一练服务及规划、月度复习规划指导、阶段规划及总结、阶段性模拟测试、测试结果统计及分析、阶段测试讲解、不限次数答疑
      等；
    </div>
    <div class="content2 content">
      <div class="item">
        <img class="item-icon" src="@/style/image/service/icon1.png">
        <div class="item-title">学习服务</div>
        <div class="item-text">
          提醒各阶段教学安排和课程进度，推动课程更新消息和考场通知，检测听课学习状态并实时提醒
        </div>
      </div>
      <div class="item-line" />
      <div class="item">
        <img class="item-icon" src="@/style/image/service/icon2.png">
        <div class="item-title">节点提醒服务</div>
        <div class="item-text">
          考务通知、报考通知、准考证答疑提醒、考前温馨提示等咨询服务。最新政策、大纲调整等重要通知
        </div>
      </div>
      <div class="item-line" />
      <div class="item">
        <img class="item-icon" src="@/style/image/service/icon3.png">
        <div class="item-title">其他日常服务</div>
        <div class="item-text">日常问题解答，意见建议记录修改，课程评价等</div>
      </div>
    </div>
    <div class="title"><span class="blue">个性化</span>管家服务</div>
    <div class="content3 content">
      <div class="item m-b-60">
        <img class="item-icon" src="@/style/image/service/img1.png">
        <div class="item-title">个性化学习方案</div>
        <div class="item-text">日常问题解答，意见建议记录修改，课程评价等</div>
      </div>
      <div class="item m-b-60">
        <img class="item-icon" src="@/style/image/service/img2.png">
        <div class="item-title">教研答疑服务</div>
        <div class="item-text text-c">教研在线答疑解惑，解决学习中的问题</div>
      </div>
      <div class="item m-b-60">
        <img class="item-icon" src="@/style/image/service/img3.png">
        <div class="item-title">智能题库</div>
        <div class="item-text">智能题库随学随练，支持错题收藏、课程模考、模拟测试等功能</div>
      </div>
      <div class="item">
        <img class="item-icon" src="@/style/image/service/img4.png">
        <div class="item-title">专属教务服务</div>
        <div class="item-text">督促进度、检测效果、实时沟通</div>
      </div>
      <div class="item">
        <img class="item-icon" src="@/style/image/service/img5.png">
        <div class="item-title">社群管理</div>
        <div class="item-text">企业微信群交流分享经验方法</div>
      </div>
      <div class="item">
        <img class="item-icon" src="@/style/image/service/img6.png">
        <div class="item-title">模考服务</div>
        <div class="item-text">无纸化模考系统考前演练，巩固强化</div>
      </div>
    </div>
    <div class="title"><span class="blue">智能</span>服务应用</div>
    <div class="content4 content">
      <div class="item">
        <div class="item-title">促学服务</div>
        <div class="item-content">
          <div class="content-list">
            <img class="item-icon" src="@/style/image/service/imgIcon1.png">
            <div class="text">学习积分奖励</div>
          </div>
          <div class="content-list">
            <img class="item-icon" src="@/style/image/service/imgIcon1.png">
            <div class="text">阶段性活动课</div>
          </div>
          <div class="content-list">
            <img class="item-icon" src="@/style/image/service/imgIcon1.png">
            <div class="text">不定时促学活动</div>
          </div>
          <div class="content-list">
            <img class="item-icon" src="@/style/image/service/imgIcon1.png">
            <div class="text">学习监督</div>
          </div>
          <div class="content-but" @click="juxueHand()">前往体验</div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">督学服务</div>
        <div class="item-content">
          <div class="content-list">
            <img class="item-icon" src="@/style/image/service/imgIcon1.png">
            <div class="text">直播到课提醒</div>
          </div>
          <div class="content-list">
            <img class="item-icon" src="@/style/image/service/imgIcon1.png">
            <div class="text">学习率统计</div>
          </div>
          <div class="content-list">
            <img class="item-icon" src="@/style/image/service/imgIcon1.png">
            <div class="text">模考提醒</div>
          </div>
          <div class="content-list">
            <img class="item-icon" src="@/style/image/service/imgIcon1.png">
            <div class="text">课程通知/更新提醒</div>
          </div>
          <div class="content-but" @click="juxueHand()">前往体验</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  async created() {},
  mounted: {}
}
</script>
<style lang="less" scoped>
.service {
  width: 100%;
  background: #fff;
  padding-bottom: 120px;
  /* 标题 */
  .title {
    text-align: center;
    align-items: center;
    margin-top: 100px;
    font-size: 40px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333;
    line-height: 40px;

    .blue {
      color: #ff7235;
    }
  }
  .content {
    width: 1080px;
    margin: 48px auto 0px;
    display: flex;
  }
  .service-tips {
    width: 1080px;
    margin: 48px auto 0px;
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
  .content1 {
    width: 100%;
    height: 480px;
    background: url('~@/style/image/service/headerBg1.png');
    background-size: 100% 100%;
  }
  .content2 {
    justify-content: space-between;
    align-items: start;
    margin-top: 40px;
    .item {
      width: 306px;
      .item-icon {
        width: 150px;
        height: 168px;
        margin: 0px auto;
      }
      .item-title {
        font-size: 28px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ff7235;
        line-height: 28px;
        margin-top: 29px;
        text-align: center;
      }
      .item-text {
        margin-top: 20px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
    }
    .item-line {
      width: 1px;
      height: 320px;
      background: #dddddd;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  .content3 {
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 320px;
      height: 420px;
      background: #ffffff;
      box-shadow: 0px 0px 24px 1px rgba(146, 148, 154, 0.12);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 40px 34px 0px;
      .item-icon {
        width: 200px;
        height: 200px;
        margin: 0px auto;
      }
      .item-title {
        font-size: 28px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ff7235;
        line-height: 28px;
        margin-top: 32px;
        text-align: center;
      }
      .item-text {
        margin-top: 16px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
    }
  }
  .content4 {
    justify-content: space-between;
    .item {
      width: 510px;
      height: 450px;
      background: url('~@/style/image/service/imgBg2.png');
      background-size: 100% 100%;
      padding: 32px 28px 28px;
      .item-title {
        font-size: 32px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
        text-align: center;
      }
      .item-content {
        width: 454px;
        height: 330px;
        background: #ffffff;
        border-radius: 8px 48px 8px 8px;
        opacity: 1;
        margin-top: 28px;
        padding: 44px 0px 0px 60px;
        .content-list {
          display: flex;
          align-items: center;
          margin-bottom: 32px;
          &:nth-child(4) {
            margin-bottom: 29px !important;
          }
          .item-icon {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
          .text {
            font-size: 20px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
        }
      }
      .content-but {
        width: 334px;
        height: 54px;
        border-radius: 27px 27px 27px 27px;
        background: linear-gradient(90deg, #ff9635 0%, #ff7235 100%);
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 54px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
