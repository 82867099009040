var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service"},[_c('div',{staticClass:"content1"}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"content4 content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("促学服务")]),_c('div',{staticClass:"item-content"},[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"content-but",on:{"click":function($event){return _vm.juxueHand()}}},[_vm._v("前往体验")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("督学服务")]),_c('div',{staticClass:"item-content"},[_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('div',{staticClass:"content-but",on:{"click":function($event){return _vm.juxueHand()}}},[_vm._v("前往体验")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title",staticStyle:{"margin-top":"60px"}},[_c('span',{staticClass:"blue"},[_vm._v("全流程")]),_vm._v("深度服务")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-tips"},[_vm._v(" 三师共管，定向评估，分期定制个人专属学习备考方案，针对施教；"),_c('br'),_vm._v(" 每日一练服务及规划、月度复习规划指导、阶段规划及总结、阶段性模拟测试、测试结果统计及分析、阶段测试讲解、不限次数答疑 等； ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content2 content"},[_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/icon1.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("学习服务")]),_c('div',{staticClass:"item-text"},[_vm._v(" 提醒各阶段教学安排和课程进度，推动课程更新消息和考场通知，检测听课学习状态并实时提醒 ")])]),_c('div',{staticClass:"item-line"}),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/icon2.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("节点提醒服务")]),_c('div',{staticClass:"item-text"},[_vm._v(" 考务通知、报考通知、准考证答疑提醒、考前温馨提示等咨询服务。最新政策、大纲调整等重要通知 ")])]),_c('div',{staticClass:"item-line"}),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/icon3.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("其他日常服务")]),_c('div',{staticClass:"item-text"},[_vm._v("日常问题解答，意见建议记录修改，课程评价等")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"blue"},[_vm._v("个性化")]),_vm._v("管家服务")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content3 content"},[_c('div',{staticClass:"item m-b-60"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/img1.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("个性化学习方案")]),_c('div',{staticClass:"item-text"},[_vm._v("日常问题解答，意见建议记录修改，课程评价等")])]),_c('div',{staticClass:"item m-b-60"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/img2.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("教研答疑服务")]),_c('div',{staticClass:"item-text text-c"},[_vm._v("教研在线答疑解惑，解决学习中的问题")])]),_c('div',{staticClass:"item m-b-60"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/img3.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("智能题库")]),_c('div',{staticClass:"item-text"},[_vm._v("智能题库随学随练，支持错题收藏、课程模考、模拟测试等功能")])]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/img4.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("专属教务服务")]),_c('div',{staticClass:"item-text"},[_vm._v("督促进度、检测效果、实时沟通")])]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/img5.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("社群管理")]),_c('div',{staticClass:"item-text"},[_vm._v("企业微信群交流分享经验方法")])]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/img6.png")}}),_c('div',{staticClass:"item-title"},[_vm._v("模考服务")]),_c('div',{staticClass:"item-text"},[_vm._v("无纸化模考系统考前演练，巩固强化")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"blue"},[_vm._v("智能")]),_vm._v("服务应用")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-list"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/imgIcon1.png")}}),_c('div',{staticClass:"text"},[_vm._v("学习积分奖励")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-list"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/imgIcon1.png")}}),_c('div',{staticClass:"text"},[_vm._v("阶段性活动课")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-list"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/imgIcon1.png")}}),_c('div',{staticClass:"text"},[_vm._v("不定时促学活动")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-list"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/imgIcon1.png")}}),_c('div',{staticClass:"text"},[_vm._v("学习监督")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-list"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/imgIcon1.png")}}),_c('div',{staticClass:"text"},[_vm._v("直播到课提醒")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-list"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/imgIcon1.png")}}),_c('div',{staticClass:"text"},[_vm._v("学习率统计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-list"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/imgIcon1.png")}}),_c('div',{staticClass:"text"},[_vm._v("模考提醒")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-list"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("@/style/image/service/imgIcon1.png")}}),_c('div',{staticClass:"text"},[_vm._v("课程通知/更新提醒")])])
}]

export { render, staticRenderFns }